@import "variables";

@media (max-width: $bp-small) {
  #refinar-busca .refinar-busca-menu .produtos ul,
  #refinar-busca .refinar-busca-menu .sabores ul,
  #refinar-busca .refinar-busca-menu .price ul {
    padding: 0;
    columns: 1;
    -webkit-columns: 1;
    -moz-columns: 1;
  }
  .product-list {
    width: 320px;
  }
  .product-list li .link h3 {
    font-size: 16px;
    width: 100%;
    max-width: 250px;
    padding: 20px 30px 3px;
  }
  .product-list li .link p {
    width: 100%;
    max-width: 200px;
    margin-top: -2px;
    padding: 0px 10px 3px 10px;
    font-size: 12px;
    font-family: martelheavy;
  }
  .product-list li .link span {
    width: 128px;
    padding: 3px 10px;
    font-size: 25px;
  }
  .product-list li .link span small {
    font-size: 18px;
    margin-right: 2px;
  }
  .product-list li .link img {
    width: 100%;
  }
  .product-list.mais-vendidos li .sabores {
    width: 167px;
    height: 20px;
    font-size: 10px;
    margin-left: -84px;
  }
  .product-list.mais-vendidos li .sabores:before {
    content: '';
    width: 52px;
    margin-left: -26px;
  }
  #best-seller-carousel-mobile {
    padding-bottom: 38px;
  }
  #identificacao .center-identification {
    width: 310px;
    margin-top: 92px;
  }
  #identificacao .center-identification .left-indentification,
  #identificacao .center-identification .middle-indentification,
  #identificacao .center-identification .right-indentification {
    padding: 0 0 15px 0;
  }
  #identificacao .center-identification .left-indentification .title,
  #identificacao .center-identification .middle-indentification .title,
  #identificacao .center-identification .right-indentification .title {
    font-size: 18px;
    padding: 18px;
  }
  #identificacao .center-identification .left-indentification {
    padding-bottom: 15px;
    margin-top: 5px;
  }
  #identificacao .center-identification .left-indentification .form-group {
    padding: 0 20px;
    text-align: center;
  }
  #identificacao .center-identification .left-indentification .form-group div {
    margin: 10px 0;
  }
  #identificacao .center-identification .left-indentification .form-group div label {
    font-size: 10px;
  }
  #identificacao .center-identification .left-indentification .form-group div input {
    height: 30px;
    border: none;
  }
  #identificacao .center-identification .left-indentification .form-group button {
    width: 170px;
  }
  #identificacao .center-identification .left-indentification a {
    margin: 25px 0px 0 0;
    font-size: 10px;
  }
  #identificacao .center-identification .middle-indentification {
    margin-top: 50px;
  }
  #identificacao .center-identification .middle-indentification .text {
    text-align: center;
    margin: 0 13px;
    font-size: 13px;
  }
  #identificacao .center-identification .middle-indentification a {
    width: 170px;
    margin: 10px auto 0;
  }
  #identificacao .center-identification .right-indentification {
    margin-top: 5px;
  }
  #identificacao .center-identification .right-indentification .text {
    margin: 0 13px 10px 13px;
    font-size: 13px;
  }
  #identificacao .center-identification .right-indentification .fb a {
    width: 148px;
    height: 31px;
    background-size: cover;
  }
  #identificacao .center-identification .right-indentification .ob a {
    width: 148px;
    height: 31px;
    background-size: cover;
  }
  #loja-online h2 {
    padding: 15px 0;
  }
  #loja-online ul {
    padding: 0 5px 0 5px;
  }
  #loja-online ul li {
    margin: 10px 0;
  }
  #loja-online ul li .panel-heading a {
    padding: 10px 0;
    font-size: 24px;
  }
  #loja-online ul li .well {
    padding: 25px 10px;
  }
  #loja-online ul li #como-comprar-mobile p {
    line-height: 16px;
    font-size: 13px;
  }
  #loja-online ul li #como-comprar-mobile .sub-title {
    margin-bottom: 3px;
  }
  #loja-online ul li #como-comprar-mobile span {
    font-size: 13px;
    line-height: 16px;
  }
  #loja-online ul li #entrega-mobile-2 p {
    line-height: 16px;
    font-size: 13px;
  }
  #loja-online ul li #entrega-mobile-2 p > a {
    font-size: 13px !important;
  }
  #loja-online ul li #entrega-mobile-2 .title {
    margin-bottom: 3px;
  }
  #loja-online ul li #termo-de-uso-mobile p {
    line-height: 16px;
    font-size: 13px;
  }
  .request-container {
    width: 100%;
    // height: 70px;
    line-height: 25px;
    padding: 10px;
    background: none;
  }
  .request-container .purple {
    display: block;
  }
  // .active-navbar-phone {
  //   top: 70px !important;
  // }
  .active-navbar-phone #menu-mobile > ul > li.has-submenu .submenu.open {
    top: 145px !important;
    height: calc(100% - 145px) !important;
  }
  #cada-trufa+div .box {
    padding: 15px 10px 0 !important;
  }
  #truffes .mobile .carousel ul.item li {
    height: 100px;
  }
  #truffes .mobile .carousel ul.item li a .img {
    height: 75px;
  }
  #truffes .mobile .carousel ul.item li a .img img {
    max-height: 75px;
  }
  #truffes .mobile .carousel ul.item li a p {
    font-size: 10px;
    margin: 8px 0 0 0;
  }
  #truffes .mobile.title {
    margin-top: 25px;
  }
  #modal-truffas .carousel ul li .title {
    margin: 15px auto;
  }
  #modal-truffas .carousel ul li .title p {
    font-family: 'martelregular';
    font-weight: normal;
    font-size: 22px;
    color: #3c0f74;
  }
  #modal-truffas .carousel ul li .img-modal {
    text-align: center;
    width: 124px;
    margin: auto;
  }
  #modal-truffas .carousel ul li .img-modal img {
    width: 100%;
    height: auto;
  }
  #modal-truffas .carousel ul li .description-modal {
    padding: 15px 25px;
  }
  #modal-truffas .carousel ul li .description-modal p {
    font-family: 'martelregular';
    font-weight: normal;
    font-size: 14px;
    color: #3c0f74;
  }
  #modal-truffas .carousel .arrows {
    width: 200px;
  }
  #modal-truffas .carousel .arrows .number p {
    font-family: 'martellight';
    font-weight: normal;
    font-size: 14px;
    color: #c8b59e;
  }
  #modal-truffas .carousel .arrows .number .active {
    font-family: 'martelregular';
    font-weight: normal;
    font-size: 22px;
    color: #775ca7;
    line-height: 25px;
  }
  #modal-truffas .carousel .arrows .arrow-left .left {
    left: -70px !important;
    bottom: 7px !important;
  }
  #modal-truffas .carousel .arrows .arrow-right .right {
    right: -70px !important;
    bottom: 7px !important;
  }
  #blocos {
    margin-top: -103px;
    margin-bottom: 29px;
  }
  #blocos .left-blocos {
    width: 239px;
    height: auto;
    padding: 15px;
  }
  #blocos .left-blocos.purple-left {
    background-size: cover;
  }
  #blocos .left-blocos h3 {
    font-size: 18px;
    margin-top: 10px;
    line-height: 20px;
    margin-bottom: 10px;
  }
  #blocos .left-blocos p {
    font-size: 13px;
    line-height: 15px;
  }
  #blocos .left-blocos ul li {
    font-size: 10px;
  }
  #blocos .left-blocos ul.list {
    margin: 25px 0 0 0;
  }
  #blocos .left-blocos ul.list:before {
    width: 1px;
    height: 77px;
    top: 8px;
  }
  #blocos .left-blocos ul.list li {
    padding: 8px;
  }
  #blocos .right-blocos {
    height: 290px;
    margin-top: 25px;
    margin-bottom: 25px;
  }
  #blocos .right-blocos img {
    width: 320px;
  }
  #blocos .right-blocos .text {
    padding: 30px;
  }
  #blocos .right-blocos .text p {
    font-size: 13px;
    margin-top: 0;
  }
  #blocos .right-blocos .text h3 {
    font-size: 18px;
  }
  #blocos .right-blocos .text a.link {
    margin-top: 27px;
    font-size: 10px;
  }
  #blocos ul.links {
    width: 125px;
    padding: 25px 0 0;
  }
  #blocos ul.links li {
    margin: 2px 0;
  }
  #blocos ul.links li a {
    padding: 0 0 0 10px;
    font-size: 10px;
  }
  #blocos ul.links li a:after {
    width: 7px;
    bottom: 8px;
  }
  #mais-vendidos .mais-vendidos-menu li a {
    font-size: 10px;
  }
  .bg-danger {
    border: #ae1f6f 1px solid;
    font-size: 10px;
  }
  .popup {
    overflow-y: auto;
  }
  .popup-wrapper,
  .popup-msg-success {
    width: 100%;
    margin: 0;
  }
  .popup-content {
    width: 85%;
    height: auto;
    background: #fff;
    border: 2px solid #3f2f60;
  }
  .popup-content .img-mobile {
    display: block;
    max-width: 80%;
    margin-bottom: 35px;
  }
  .popup-wrapper {
    float: none;
    padding: 0 25px 30px 25px;
  }
  .popup-title {
    margin-bottom: 10px;
  }
  .popup-descr {
    font-size: 14px;
    margin-bottom: 15px;
  }
  .popup-msg-success {
    right: 0;
    top: 0;
    position: relative;
    height: 250px;
    padding: 0 20px;
    -moz-transform: translate(0, 0);
    -webkit-transform: translate(0, 0);
    -o-transform: translate(0, 0);
    -ms-transform: translate(0, 0);
    transform: translate(0, 0);
  }
  .popup-form {
    width: 100%;
    max-width: 245px;
    margin: 0 auto;
  }
  #spanPrecoMinimo,
  #spanPrecoMaximo {
    font-size: 11px;
  }

  .filter-align {
    display: flex;
    justify-content: space-around;
    margin-bottom: 10px;
  }
}